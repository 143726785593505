
$border-color-light-grey: #8C8C8C;

$color-orangered: #E3353B;
$color-grey: #707070;
$color-white: #FFFFFF;
$color-black: #1B1B1B;
$color-black-text: #1B1B1B;
$color-orange: #E38635;
$color-light-gray: #BBBBBB;
$color-sky-blue: #28BAE8;
$font-weight-bold: bold;
$height-30px: 30px;
$font-size-8px: 8px;
$font-size-10px: 10px;
$font-size-15px: 15px;
$input-focus: #F6A9AC;
$bg-blue: rgb(40, 186, 232,0.3);
$bg-orange:rgb(227, 134, 53 ,0.3);
$bg-lightRed:rgb(227, 53, 59,0.3);
$bg-lightGrey:rgb(255, 255, 255 , 65%);
$bg-backgroundGrey:#E5E5E5;
$bg-greyFaint :rgb(112, 112, 112,30%)
