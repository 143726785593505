/* You can add global styles to this file, and also import other style files */
@import "./assets/scss/app.scss";
@import "./variables";

@font-face {
  font-family: "Rubik-Medium";
  src: local("Rubik-Medium"),
    url(./assets/fonts/Rubik/Rubik-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Rubik-bold";
  src: local("Rubik-bold"),
    url(./assets/fonts/Rubik/Rubik-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Rubik-Regular";
  src: local("Rubik-Regular"),
    url(./assets/fonts/Rubik/Rubik-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Rubik-light";
  src: local("Rubik-light"),
    url(./assets/fonts/Rubik/Rubik-Light.ttf) format("truetype");
}

.router-wrapper {
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: calc(100% - 47px);
  perspective: 1200px;
  transform-style: preserve-3d;
}

:host {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #60d7a9;
}

* {
  letter-spacing: 0 !important;
  font-family: "Rubik-Medium";
}

input {
  background-color: $color-white !important;
  color: $color-grey !important;
  border: 1px solid $color-black !important;
  border-radius: 4px;
  font-size: 12px !important;
  font-family: "Rubik-Medium";
}

input:focus {
  border: 1px solid $input-focus !important;
}

.form-control:focus {
  border: 1px solid $input-focus !important;
  box-shadow: none;
}

input::placeholder {
  font-size: 10px !important;
  color: $color-light-gray !important;
  font-family: "Rubik-Medium";
}

.form-control.border-faint {
  border: 1px solid $color-light-gray !important;
}

select {
  display: block;
  width: 100%;
  border: 1px solid $color-black !important;
  border-radius: 4px;
  font-size: 12px !important;
}

select:focus {
  outline-color: $input-focus !important;
}

.cursor-pointer {
  cursor: pointer;
}

// classes for width
.w-100px {
  width: 100px;
}

// classes for width

// classes for fonts//
.font-weight-bold {
  font-weight: $font-weight-bold !important;
}

// classes for fonts//

// padding classes

.p-r-4px {
  padding-right: 4px !important;
}
// padding classes

// login css//
.login-card {
  background: $color-black !important;
}

// login css//

// color-classes
.color-orangered {
  color: $color-orangered !important;
}

.color-gray {
  color: $color-grey !important;
}

.color-lighter-grey {
  &:not(.color-orange){
    color: $border-color-light-grey !important;
  }
}

.color-white {
  color: $color-white !important;
}

.color-orange {
  &:not(.color-lighter-grey){
    color: $color-orange !important;
  }
  
}

.color-black {
  color: $color-black !important;
}

.color-light-gray {
  color: $color-light-gray !important;
}

.color-skyBlue {
  color: $color-sky-blue !important;
}

// background-color
.background-orangered {
  background-color: $color-orangered !important;
}

.background-grey {
  background-color: $color-grey !important;
}

.background-faintGrey {
  background-color: $bg-greyFaint !important;
}

.color-transparent{
  color: transparent !important;
  cursor: not-allowed !important;
}

.background-white {
  background-color: $color-white !important;
}

.background-orange {
  background-color: $color-orange !important;
}

.background-skyBlue {
  background-color: $color-sky-blue !important;
}

.background-light-grey {
  background-color: $bg-lightGrey !important;
}

.bg-grey {
  background-color: $bg-backgroundGrey !important;
}

// background-color

.btn-signIn,
.btn-signIn:hover {
  border-radius: 4px;
  color: $color-white;
}

// color-classes

//border-radius classes start
.border-r-15 {
  border-radius: $font-size-15px !important;
}

//border-radius End

// height classes

.line-height-30px {
  line-height: $height-30px;
}

.h-30px {
  height: $height-30px;
}

// height classes

// sideBar css

.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper {
  width: 240px;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .sidebar-link.active {
  background-color: $color-orangered;

  span,
  i,
  svg {
    color: $color-white;
    stroke: $color-white;
  }
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .sidebar-link:hover {
  background-color: $color-orangered !important;

  span,
  i,
  svg {
    color: $color-white !important;
    stroke: $color-white !important;
  }
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  .sidebar-list
  ul.sidebar-submenu
  li
  a.active,
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  .sidebar-list
  ul.sidebar-submenu
  li
  a.active::after {
  color: $color-orangered !important;
  border-color: $color-orangered !important;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  .sidebar-list
  ul.sidebar-submenu
  li
  a,
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  .sidebar-list
  ul.sidebar-submenu
  li
  a::after {
  color: $color-black;
  border-color: $color-black !important;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .sidebar-link.active
  .according-menu
  i {
  color: $color-white;
}

.page-wrapper .page-body-wrapper .page-body {
  padding: 15px;
}

.page-wrapper.compact-wrapper .page-body-wrapper .page-body {
  margin-left: 240px;
}

.page-wrapper.compact-wrapper .page-body-wrapper .page-body {
  // min-height: calc(100vh - 130px) !important;
  margin-top: 81px;
  margin-left: 240px;
  overflow: auto !important;
  height: calc(100vh - 320px);
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .sidebar-submenu
  li
  a:hover {
  color: $color-orangered !important;
  border-color: $color-orangered !important;
}

.page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown {
  width: 160px;
  top: 40px;
}

// sideBar css

// page footer
.footer {
  margin-left: 240px;
}

// page footer

// header-css
.page-wrapper.compact-wrapper .page-header {
  margin-left: 240px;
  width: calc(100% - 240px);
}

// header-css

// nav css
.nav-tabs .nav-link {
  color: $color-black;
  font-family: "Rubik-Medium";
}

.nav-pills .nav-link.active {
  background: transparent;
  color: $color-orange;
}

// nav css

.ngx-datatable.overView {
  .datatable-header {
    .datatable-header-cell {
      .datatable-header-cell-template-wrap {
        div {
          span,
          svg,
          input[type="checkbox"] {
            cursor: default !important;
          }
        }
      }
    }
  }
  .datatable-body {
    .datatable-body-row {
      .datatable-body-cell {
        cursor: default;
      }
    }
  }
}

.overView-height {
  .ngx-datatable.bootstrap .datatable-body {
    height: calc(100vh - 455px) !important;
  }
  .ngx-datatable.bootstrap {
    min-height: calc(100vh - 420px) !important;
  }
}

//***ngx datatable***
.ngx-datatable.bootstrap {
  min-height: calc(100vh - 230px);

  .datatable-header {
    // .datatable-header-cell-template-wrap{
    //   white-space: break-spaces;
    // }
    width: auto !important;
    background-color: $color-black;
    display: flex;
    align-items: center;

    div {
      color: $color-white;
      font-size: 14px; // dhanashree

      svg {
        cursor: pointer;
      }
    }

    .datatable-header-cell {
      padding: 0 2.1rem;

      .datatable-header-cell-template-wrap {
        display: flex;

        &.make-center {
          text-align: center !important;
        }

        div {
          span {
            white-space: break-spaces;
            cursor: pointer;
          }
        }
      }

      .datatable-header-cell-label {
        font-family: "Rubik-Medium" !important;
        cursor: pointer;
      }
    }
  }

  .datatable-body {
    height: calc(100vh - 320px) !important;
    width: auto !important;
    overflow-x: hidden !important;

    /* width */
    &::-webkit-scrollbar {
      width: 4px;
      height: 7px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    .datatable-scroll {
      width: 100% !important;
    }

    .datatable-row-wrapper {
      width: 100% !important;
    }

    .datatable-body-row {
      width: auto !important;
      border-bottom: 1px solid $color-light-gray;
      background-color: $color-white !important;
      cursor: pointer;

      &.active {
        background-color: transparent !important;
        color: $color-black !important;
      }

      &[ng-reflect-is-selected="true"]:hover {
        background-color: transparent !important;
        color: $color-black !important;
      }

      .datatable-body-cell {
        padding: 0 2.1rem;
        line-height: 1.5;
        display: flex;
        align-self: center;

        .datatable-body-cell-label {
          display: block;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          align-self: center;
          color: $color-black;
          font-size: 14px;
          opacity: 0.97 !important;
          font-family: "Rubik-Medium" !important; //dhanashree
          .checkboxx {
            width: 25px !important;
            height: 25px !important;
          }

          input[type="checkbox"] {
            border: 1px solid $color-light-gray !important;
            border-radius: 2px;
            appearance: none;
            width: 12px;
            height: 12px;
            font-size: 12px !important;
            font-family: "Rubik-Medium";
          }
          input[type="checkbox"]:checked {
            appearance: auto;
          }
        }
      }
    }
  }

  .datatable-footer {
    .datatable-footer-inner {
      .page-size-control {
        .page-size-dropdown {
          padding: 0 8px;
          border: 1px solid $color-orange !important;
          cursor: pointer;
        }
      }

      .pagination-control-leaderboard {
        .page-link {
          padding: 0 8px;
          color: $color-orange !important;
          border-radius: 0 !important;
          border: 1px solid $color-orange !important;
          font-size: 14px;
        }

        .page-item.active .page-link {
          background-color: $color-orange !important;
          border-color: $color-orange !important;
          color: $color-white !important;
          font-size: 14px;
        }
      }

      .pagination-control {
        .page-link {
          padding: 0 8px;
          color: $color-orangered !important;
          border-radius: 0 !important;
          border: 1px solid $color-orangered !important;
          font-size: 14px;
          // transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
          // span {
          //   font-family: sans-serif;
          //   font-size: 15px;
          //   line-height: 0.9;
          //   vertical-align: middle;
          // }
        }

        .page-item.active .page-link {
          background-color: $color-orangered !important;
          border-color: $color-orangered !important;
          color: $color-white !important;
          font-size: 14px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1442px) {
  .ngx-datatable.bootstrap {
    .datatable-body {
      height: calc(100vh - 320px) !important;
      width: 100% !important;
      overflow-x: scroll !important;

      /* width */
      &::-webkit-scrollbar {
        width: 4px;
        height: 7px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      .datatable-scroll {
        width: auto !important;
      }

      .datatable-row-wrapper {
        width: 100% !important;
      }

      .datatable-body-row {
        border-bottom: 1px solid $color-light-gray;
        background-color: $color-white !important;
        cursor: pointer;

        &.active {
          background-color: transparent !important;
          color: $color-black !important;
        }

        &[ng-reflect-is-selected="true"]:hover {
          background-color: transparent !important;
          color: $color-black !important;
        }

        .datatable-body-cell {
          padding: 0 2.1rem;
          line-height: 1.5;
          display: flex;
          align-self: center;

          .datatable-body-cell-label {
            display: block;
            align-self: center;
            color: $color-black;
            // font-size: 14px;
            font-family: "Rubik-Medium" !important; //dhanashree
            opacity: 0.97 !important;
            font-size: 14px !important;
          }
        }
      }
    }
  }
}

//***/ngx datatable***

// card css
.card {
  border-radius: 4px;
}

// card css

.col-form-label {
  padding-bottom: 2px;
  font-size: 13px !important;
  font-weight: 500;
}

// add by dhanashree
textarea {
  background-color: $color-white !important;
  color: $color-grey !important;
  border: 1px solid $color-black !important;
  border-radius: 4px;
}

textarea:focus,
select:focus {
  border: 1px solid $input-focus !important;
}

.f-8 {
  font-size: $font-size-8px !important;
}

.f-10 {
  font-size: $font-size-10px !important;
}

.login-main,
.modal-dialog {
  form {
    span,
    p {
      font-family: "Rubik-Medium";
    }

    button span {
      font-family: "Rubik-bold";
    }
  }
}

.show-hide span {
  font-size: 12px;
}

.font-rubik {
  font-family: "Rubik" !important;
}

.f-15 {
  font-size: $font-size-15px !important;
}

.checkboxx {
  height: 25px;
  width: 25px;
  border-radius: 5px !important;
  border: 1px solid $color-light-gray !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
}

// ngx image cropper start
.ngx-ic-source-image {
  width: 100% !important;
}

.ngx-ic-cropper {
  border: 2px solid #e38635;
  outline: rgb(233 121 5 / 35%) solid 100vw !important;
}

// ngx image cropper end

.font-rubik-medium {
  font-family: "Rubik-Medium" !important;
}

.font-Rubik-bold {
  font-family: "Rubik-bold" !important;
}

.font-Rubik-regular {
  font-family: "Rubik-Regular" !important;
}

.font-Rubik-light {
  font-family: "Rubik-light" !important;
}

// calender icon css
input[type="date"]:required:invalid::-webkit-datetime-edit {
  visibility: hidden !important;
}
input[type="time"]:required:invalid::-webkit-datetime-edit {
  visibility: hidden !important;
}

// input[type=date]:focus::-webkit-datetime-edit {
//   visibility: hidden !important;
// }

input[type="date"]::-webkit-calendar-picker-indicator {
  content: url("assets/fonts/calendar/icon.svg");
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 3px;
  position: relative;
  right: 0;
  top: 0px;
  width: auto;
}

input[type="checkbox"] {
  cursor: pointer;
}

//FOR IMAGE BORDER AND SHADOW
.ImgOutlook {
  border: 2px solid $color-orangered !important;
  border-radius: 8px;
  box-shadow: rgb(225 27 27 / 53%) 0px 2px 8px 0px !important;
}

.ImgOutlook-none {
  border: none !important;
  border-radius: 0% !important;
  box-shadow: none !important;
}

.ImgOutlook-leader {
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
//FOR IMAGE BORDER AND SHADOW

.position-leader {
  display: grid;
}

.shadow-btn {
  border: none;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  cursor: pointer;
}

.chart-bg-color {
  background-color: #eaeaea;
}

.btn-skyBlue {
  background-color: $bg-blue;
  border: 2px solid $color-sky-blue;
  width: 120px;

  span {
    color: $color-sky-blue;
  }
}

.btn-orange {
  background-color: $bg-orange;
  border: 2px solid $color-orange;
  width: 120px;

  span {
    color: $color-orange;
  }
}

.btn-lightRed {
  background-color: $bg-lightRed;
  border: 2px solid $color-orangered;
  width: 120px;

  span {
    color: $color-orangered;
  }
}

.font-rubik-medium {
  font-family: "Rubik-Medium" !important;
}

.font-Rubik-bold {
  font-family: "Rubik-bold" !important;
}

.font-Rubik-regular {
  font-family: "Rubik-Regular" !important;
}

.font-Rubik-light {
  font-family: "Rubik-light" !important;
}

//add by vrushabh

// calender icon css
input[type="date"]:required:invalid::-webkit-datetime-edit {
  visibility: hidden !important;
}

// input[type=date]:focus::-webkit-datetime-edit {
//   visibility: hidden !important;
// }

input[type="date"]::-webkit-calendar-picker-indicator {
  content: url("assets/fonts/calendar/icon.svg");
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 3px;
  position: relative;
  right: 0;
  top: 0px;
  width: auto;
}

input[type="checkbox"] {
  cursor: pointer;
}

input[type="checkbox"]:checked {
  appearance: auto;
}

// visibility hidden

.vis-Hidden {
  visibility: hidden !important;
}

// changes Model popUp Width
@media (min-width: 768px) {
  .modal-md {
    min-width: 635px;
  }
}

// changes Model popUp Width

//sweet alert
.swal2-popup {
  width: auto !important;

  .swal2-header {
    padding: 0 1em !important;
  }

  .swal2-title {
    font-size: 16px !important;
    font-family: "Rubik-bold" !important;
    color: $color-black !important;
  }

  .swal2-actions {
    .swal2-confirm.swal2-styled,
    .swal2-cancel.swal2-styled {
      padding: 0.25em 0.75rem !important;
      font-size: 12px !important;
    }

    .swal2-confirm.swal2-styled {
      background-color: $color-orange !important;
    }

    .swal2-cancel.swal2-styled {
      background-color: $color-grey !important;
    }
  }
}

// search cross icon position
.search-cross-icon {
  position: relative;
  right: 25px;
  top: 12px;
  cursor: pointer;
}

// search cross icon position

// for image-cropper model box
.modal-content {
  border: 1px solid $color-orange !important;
}

.image-cropper-new {
  .modal-content {
    border-radius: 40px;
    border: 1px solid $color-orange !important;
    .card {
      border-radius: 40px;
    }
  }
}
// for image-cropper model box

//for apex-chart tooltip
.apexcharts-tooltip {
  width: 120px !important;
  height: 80px !important;
  background: $color-light-gray !important;
  -moz-border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  border-radius: 15px !important;
  font-size: 12px !important;
  font-weight: 100 !important;
}

.apexcharts-tooltip-text {
  font-family: Rubik-medium !important;
  font-size: 12px !important;
  font-weight: 100 !important;
  color: $color-black;
}

.apexcharts-tooltip.apexcharts-theme-light {
  border: none !important;
  padding: 8px !important;
}

.apexcharts-tooltip-title {
  display: none !important;
}

.apexcharts-tooltip-marker {
  display: none !important;
}

// for apex-chart tooltip

::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.justify-content-center{
  justify-content: center !important;
}
.justify-content-around{
  justify-content: space-around !important;
}
