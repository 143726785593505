/**=====================
    24. According CSS start
==========================**/
.accordion {
    & > .card {
        box-shadow: none;
        border: $card-border-color !important;
        margin-bottom: 10px !important;
        .card-header {
            margin: -1px;
            padding: 20px;
            .btn {
                padding: 0;
            }
            .btn-link {
                color: $theme-body-font-color;
                font-weight: 500;
                &:hover,
                &:focus {
                    text-decoration: none;
                }
            }
        }
        &:last-child {
            margin-bottom: 0 !important;
        }
    }
}
.default-according{
    .accordion-header{
        svg{
            margin-right: 10px;
        }
        .accordion-button{
            color: $theme-body-font-color;
            &[aria-expanded=true]{
                color: $primary-color;
                background-color: rgba(115, 102, 255, 0.15);
            }
        }
    }
    .accordion-button:not(.collapsed)::after{
          display: none;  
    }
    .card{
        margin-bottom: 0;
        box-shadow: none;
        &+.card{
            margin-top: $according-card-top-margin;
        }
        .btn-link{
            font-weight: $according-btn-weight;
            color: $theme-body-font-color;
            text-decoration: none;
        }
        .btn-link{
            &:focus,&:hover{
                text-decoration: none;
            }
        }
        .card-header{
            padding: $according-card-header-padding;
            border: $card-border-color;
            i{
                position: absolute;
                left: 18px;
                font-size: 20px;
                top: 20px;
            }
            h5{
                margin-top: 2px;
            }
        }
        .card-body {
            border: $card-border-color;
            border-top: none;
            padding: 25px 20px;
        }

    }
}
.default-according.style-1{
    button{
        width: 100%;
        text-align: left;
        &:before{
            right: 20px;
            position: absolute;
            transition: 0.4s;
        }
        &[aria-expanded="true"]{
            &:before{
                content: $according-open-icon;
                font-family: $according-card-header-icon;
            }
        }
        &[aria-expanded="false"]{
            &:before{
                content: $according-close-icon;
                font-family: $according-card-header-icon;
            }
        }
    }
}
/**=====================
     24. According CSS Ends
==========================**/